import React, { useMemo } from 'react';
import clsx from 'clsx';
import { SocialProofStyles as SocialContainer } from '../../styles/Components/SocialProof/SocialProofStyles';
import PressMentionWrapper from './PressMentionWrapper';

const SocialProof = ({ component }) => {
  const isDark = component?.variant === 'Dark';

  const pressMentions = useMemo(
    () =>
      component?.pressMentions.map(press => (
        <PressMentionWrapper
          pressLogo={isDark ? press.darkLogo : press.lightLogo}
          articleLink={press.featuredArticle}
          companyName={press.name}
        />
      )),
    [component.pressMentions, isDark]
  );

  return (
    <SocialContainer className={clsx(isDark && 'is-dark')}>
      <p className="sub-head">{component?.subhead}</p>
      <div className="press-wrapper">{pressMentions}</div>
    </SocialContainer>
  );
};

export default SocialProof;
