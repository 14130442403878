import styled from 'styled-components';
import { Container } from 'react-bootstrap';

export const SocialProofStyles = styled(Container)`
  background-color: var(--white);
  border: 1px solid #d8dae6;
  box-sizing: border-box;
  text-align: center;
  padding: 40px 15px;
  margin-bottom: 48px;
  .sub-head {
    font-size: 16px;
    color: #6f7283;
  }
  &.is-dark {
    background-color: var(--dark-color);
    .sub-head {
      color: var(--white);
    }
  }
  @media (max-width: 991px) {
    width: 95% !important;
  }

  .press-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    @media (max-width: 991px) {
      margin-top: 32px;
    }
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }
    .gatsby-image-wrapper {
      margin: 0 30px;
      @media (max-width: 1280px) {
        margin: 0 16px;
      }
      @media (min-width: 992px) and (max-width: 1200px) {
        margin: 0 14px;
      }
      @media (max-width: 991px) {
        [data-main-image] {
          max-height: 28px;
          width: auto;
          margin: 0 auto;
        }
        img {
          margin-bottom: 16px;
        }
      }
    }
  }
`;
