import React from 'react';
import Link from '../../utils/Link';
import OptimizedImage from '../../utils/OptimizedImage';

const PressMentionWrapper = ({ companyName, articleLink, pressLogo }) => (
  <Link to={articleLink}>
    {pressLogo.image?.gatsbyImageData ? (
      <OptimizedImage
        image={pressLogo.image.gatsbyImageData}
        alt={`Logo of ${companyName}`}
      />
    ) : (
      <OptimizedImage
        src={pressLogo.image?.url}
        alt={`Logo of ${companyName}`}
      />
    )}
  </Link>
);

export default PressMentionWrapper;
